<template>
  <div class="row">
    <div class="col-12">
      <label class="col-form-label"> {{ label }}: </label>
      <input
        :name="name.toLowerCase()"
        :type="updatedFile == false ? 'file' : 'text'"
        :value="value"
        @change="updateInputChange($event)"
        @input="updateInputText($event)"
        class="form-control"
        v-validate="{
          required: required === true
        }"
      />
      <small v-show="errors.has(name.toLowerCase())" class="px-2 text-danger">
        {{ $t("field_required") }}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputFile",
  props: {
    label: {
      type: String,
      required: true
    },
    inputData: {
      type: String,
      required: true,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    urlUploadFile: {
      type: String,
      required: true,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      required: false,
      default: ""
    }
  },
  watch: {
    inputData(newVal) {
      if (newVal !== "" && this.updatedFile == false) {
        this.updatedFile = true;
        this.inputText = newVal;
      }
    }
  },
  data() {
    return {
      updatedFile: false,
      inputText: ""
    };
  },
  computed: {
    value() {
      if (this.updatedFile == true) {
        return this.inputText;
      }
      return "";
    }
  },
  methods: {
    updateInputText(event) {
      if (this.updatedFile == true) {
        this.inputText = event.target.value;
        if (this.inputText == "") {
          this.updatedFile = false;
        }
      }
    },
    updateInputChange(event) {
      if (this.updatedFile == false && event.target.files !== null) {
        this.fiileUpload(event);
      } else {
        this.$emit("update:inputData", event.target.value);
      }
    },
    fiileUpload(event) {
      let file = event.target.files[0];
      if (file && this.urlUploadFile !== "") {
        this.$emit("loading", true);
        let formData = new FormData();
        formData.append("file", file);
        this.$http
          .post(this.urlUploadFile, formData)
          .then(resp => {
            if (resp.data.file.name) {
              this.inputText = `${this.prefix}/${resp.data.file.name}`;
              this.updatedFile = true;
              this.$emit("update:inputData", this.inputText);
            }
            this.$emit("loading", false);
            this.$toastr.success(this.$t("success_add_file_to_serve"));
            return true;
          })
          .catch(() => {
            this.$toastr.error(this.$t("failed_add_file_to_serve"));
            this.$emit("loading", false);
            return false;
          });
      }
    },
    validation() {
      return this.$validator.validateAll().then(result => result);
    }
  }
};
</script>
