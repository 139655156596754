<template>
  <div class="row">
    <div class="col-12 col-md-4 b-r">
      <p>
        Wgranie regulaminu spowoduję jego natychmiastowe opublikowanie na
        stronie
      </p>
      <form role="form">
        <div class="form-group">
          <input-file
            ref="inputPhotoBackground"
            :inputData.sync="training.trainingRegulations"
            :prefix="`img/trainings`"
            label="Regulamin"
            :urlUploadFile="urlUpdateFile()"
            name="training_photo_background"
            @loading="updateFileLoading"
          />
          <small
            v-show="errors.has('training_photo_background')"
            class="text-danger"
          >
            Pole wymagane!
          </small>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputFile from "@/components/base/InputFile.vue";

export default {
  name: "TrainingRegulationsForm",
  components: {
    InputFile
  },
  data() {
    return {
      training: {
        trainingRegulations: null
      }
    };
  },
  created() {
    this.$emit("loading", false);
  },
  methods: {
    urlUpdateFile() {
      return `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/trainings/regulations`;
    },
    updateFileLoading(loading) {
      this.$emit("loading", loading);
    }
  }
};
</script>
